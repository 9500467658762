import React from 'react'
import { Logo, DashNavbar, ProfileContainer, LogoutButton, ProfileIcon } from '../../styles/NavBarStyles'
import LogoImage from '../../assets/pcz_bg_removed.png';
import { Link, useNavigate } from 'react-router-dom'


const InnerNavBar = () => {

	return (
		<DashNavbar>
			<Logo src={LogoImage} alt='Premier Credit'/>
			<ProfileContainer>
				<LogoutButton>HOME</LogoutButton>
				<ProfileIcon />
			</ProfileContainer>
		</DashNavbar>
	)
}

export default InnerNavBar
