import { createSlice, current } from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'data',
    initialState: { token: null , COA :[], Supplier:[], PayBill:[]},
    reducers: {
        setCredentials: (state, action) => {
            const info = action.payload
            state.token = info // set token in state 
        },
      
        logOut: (state, action) => {
            state.token = null
        },
    }
})

export const { setCredentials, logOut} = authSlice.actions

export default authSlice.reducer

export const selectCurrentToken = (state) => state.auth.token