import React, { useState , useEffect} from 'react';
import InnerNavBar from '../Layout/Header/InnerNavbar';
import { WinnerButtonBox , WinnerContainer, GenerateWinner} from '../styles/HomeStyles';
import Model from '../components/WinnerModel';
import CountUp from 'react-countup';
import { fetchWinner } from '../app/index';

// Redux
import { useWinnerMutation } from '../Redux/Auth/authApiSlice'

const Home = () =>{

    const [Msg, setMsg] = useState('')
    const [WinnerData, setWinnerData] = useState();
    const [counter, setCounter] = useState();
    const [openModel, setOpenModel] = useState(false);
    const [num, setNum] = useState(0);
    const [ isLoading, setLoading] = useState(false);

  function randomNumberInRange(min, max) {
    // get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  useEffect(() => {
    const interval = setInterval(() => {
      // generate random number between 5 and 7
    //   setNum(randomNumberInRange(26, 7));
    if(isLoading){
      RandomPhones();
    }
    }, 50); // runs every 1 second

    return () => {
      clearInterval(interval);
    };
  }, [isLoading]);

 

  // Fetch Winner
  const FetchAccountData = async() => {
    setLoading(true)
    console.log("testss")
    var delayInMilliseconds = 8000; //10 second
    setTimeout(async function() {
        setWinnerData(await fetchWinner());
        setOpenModel(true)
        setLoading(false)
    }, delayInMilliseconds);
    
  }

  async function RandomPhones() {
    console.log('RANDOMIZING....');
      let randomPart = getRandomInt(1000000, 9999999);
      let randomSegment = getRandomInt(7, 9);
      if (randomSegment == 8) {
        randomSegment = 9;
      }
      let randomCarrier = getRandomInt(5, 7);
      let randomPhone = '260' + randomSegment.toString() + randomCarrier.toString() + randomPart.toString();
      setNum(randomPhone);
      console.log('ROLL TEXT: ', randomPhone);
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  // Run numbers
  const LoadWinner = () => {
     return  <p>
        {num}
        {/* <CountUp
          start = {1000000}
          end = { 9999999}
          duration = {1800}
          separator = "" /> */}
        </p>
}


  return (
    <div>
        <InnerNavBar />
        <WinnerContainer>
        <WinnerButtonBox onClick={() => FetchAccountData()}> 
        { isLoading ? <LoadWinner />  : "GENERATE WINNER" }
        </WinnerButtonBox>
        </WinnerContainer>
        {openModel ? <Model setOpenModel={setOpenModel} WinnerData={WinnerData}/> : ""}
    </div>
  )
}

export default Home