import OutlinedInput from '@mui/material/OutlinedInput';
import styled from 'styled-components';
import { padding } from "@mui/system";

// Media Query Break Points
const sizes = {
    tablet: '48em',      // 768px
    laptop: '64em',      //1024px,
    laptopL: '85.375em', //1440px
    desktop: '160em',    //2560px,
  };
  
  // Devices
  export const devices = {
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
  };


export const inputDefault = {
    width: '100%',
    margin: '12px',
    marginLeft: 0,
    alignSelf: 'centre',
    borderRadius: '7px',
    padding: '4px'
}



export const OutlinedInputField = styled(OutlinedInput)`
 @media ${devices.desktop} {
   /* margin: 1.8rem;
   padding: 1.8rem; */
   width: 92%;
   height: 5rem;
  }
`;


// export const inputDefaults = styled(OutlinedInput)`
//    width: 100%;
//     margin: 12px;
//     align-self: 'centre';
//     border-radius: 7px;
//     padding: 4px;
//     margin-left: 0;
//  @media ${devices. laptopL} {
//    margin: 5rem;
//    padding: 4rem;
//   }
// `
 
