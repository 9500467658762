import React, { useState, useEffect } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput';
import { inputDefault , OutlinedInputField } from '../../styles/ComponentStyles';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';



const Input = ({
    style,
    icon,
    type,
    name,
    placeholder,
    password,
    onChange,
    borderError
}) => {

    const [inputType, setInputType] = useState(type);
    const [errorFlag, setErrorFlag] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [iconSuffix, updateIconSuffix] = useState(<Visibility></Visibility>);
    var inputStyle = {};

    const styles = {
        input1: {
          height: 50
        },
        input2: {
          height: 200,
          fontSize: "3em"
        }
      };

    useEffect(
        () => {
            if(password){
                updatePasswordParams()
              };
        },
        [passwordVisibility],
    );

    async function passwordVisibilityToggle(){
        if(!password){
            return;
        }
        setPasswordVisibility(!passwordVisibility);
    }

    async function updatePasswordParams(){
        if(passwordVisibility){
            updateIconSuffix(<VisibilityOff></VisibilityOff>);
            setInputType('text');
        }else{
            updateIconSuffix(<Visibility></Visibility>);
            setInputType('password');
        }
    }

    switch (style) {
        case 'default':
            inputStyle = inputDefault;
            break;
    
        default:
            break;
    }

    
    return(
        <OutlinedInputField 
        required
        style={inputStyle }
        // sx={{fontSize: '2rem'}}
        sx={{ fontSize: { xs: "1.1rem", md: "1rem", lg: "1rem",  xl: "1.2rem"},}}
        type={inputType}
        autoComplete="new-password"
        name={name}
        size="small"
        border={borderError}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        }
        endAdornment={
            <InputAdornment position="end">
                <IconButton disabled={!password} tabIndex={-1} onClick={(password)=>{passwordVisibilityToggle(password)}}>
                    {password ? iconSuffix : undefined}
                </IconButton>
            </InputAdornment>
        }
        onChange={onChange}
        error={errorFlag}
        />
    );
}

export default Input