import React from 'react'
import { GlobalStyles } from './styles/Globalstyles'
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Login from './pages/Login'
import Home from './pages/Home';

const App = () => {
  return (
    <>
    <GlobalStyles />
      <BrowserRouter>
      <Routes>
        <Route path="/" element={< Login />} />
        <Route path="/home" element={<Home />} />
    </Routes>
      </BrowserRouter>

</>
  )
}

export default App
