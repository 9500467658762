import styled from 'styled-components';

export const GenerateWinner = styled.button`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 30px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.2, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;

`;
export const WinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafbfc;
  width: 100%;
  height: 100%;
`;

export const WinnerButtonBox = styled.button`
    margin-top: 17.5%;
    width: 470px;
    background-color: #fff;
    box-shadow: 5px 2px 100px #ff8901;
    border-radius: 12px;
    height: 5rem;
    text-align: center;
    cursor: pointer;
    font-size: 1.3rem;
    color: #ff8901;
    font-family: 'IBM Plex Sans Condensed', sans-serif;
    font-weight: 500;
`;
