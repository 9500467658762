import axios from 'axios';

const url = 'https://draw.premiercredit.co.zm/random_selector.php';

// fetch all data
export const fetchWinner = async() => {

    try{
        const { data:{response_data:{ payload}} } = await axios.get(url);
        console.log( payload)
        return  payload;
    } catch(error) {
        console.log(error)
    }
}
