import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
//import { logOut } from '../../Redux/Auth/authSlice'
const baseQuery = fetchBaseQuery({
    baseUrl: 'https://insureapi.premiercredit.a2hosted.com',
    //credentials: 'include',
    mode: "cors",
    //credentials: "same-origin",
    prepareHeaders: (headers, { getState }) => {
      // const token = getState().auth.token
   //    if (token) {
           //headers.set("authorization", `Bearer ${token}`)
           headers.set("Content-Type", "application/json");
      //  }
        return headers
    }
})
const baseQueryWithAuth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    // If access token has expired logout user
    if (result.status === 403) {
       //  await baseQuery('/auth/expiretoken', api, extraOptions)
        console.log('loading out user')
     //   api.dispatch(logOut())
    }
    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithAuth,
    tagTypes: ['payloadData', 'Supplier'],
    endpoints: builder => ({})
})




// // Winner base url
// const baseQueries = fetchBaseQuery({
//     baseUrl: 'https://draw.premiercredit.co.zm', //https://draw.premiercredit.co.zm/random_selector.php
//     //credentials: 'include',
//     mode: "cors",
//     //credentials: "same-origin",
//     prepareHeaders: (headers, { getState }) => {
//       // const token = getState().auth.token
//        // if (token) {
//            //headers.set("authorization", `Bearer ${token}`)
//            headers.set("Content-Type", "application/json");
//       //  }
//         return headers
//     }
// })
// const baseQueriesWithAuth = async (args, api, extraOptions) => {
//     let result = await baseQueries(args, api, extraOptions)
//     // If access token has expired logout user
//     // if (result.status === 403) {
//     //    //  await baseQuery('/auth/expiretoken', api, extraOptions)
//     //     console.log('loading out user')
//     //   //  api.dispatch(logOut())
//     // }
//     return result
// }



// export const apiSlice = createApi({
//     baseQuery: baseQueriesWithAuth,
//    // tagTypes: ['payloadData', 'Supplier'],
//     endpoints: builder => ({})
// })