import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';

export const ModelContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
`;

export const Center = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
`;

export const Models = styled.div`
  width: 90%;
  padding-bottom: 3rem ;
  height: 50%;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
`;

export const WinnerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModelHead = styled.div`
  height: 3rem;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const ModelHeading = styled.h4`
  color: #FF0000;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  padding-top: 9%; 
  font-size: 1.7rem;
  text-align: center;
`;

export const WinnerName= styled(ModelHeading)`
 margin-top: 0;
 color: #020528 ;
 font-size: 1.7rem;
 padding-top: 8%; 
`;

export const WinnerNumber = styled(ModelHeading)`
 color: #020528 ;
 font-size: 2rem;
 padding-top: 8%; 
`;

export const ModelContent = styled.div`
width: 100%;
color: #000000;
padding: 0.3rem 2rem ;
`;

export const ModelAction = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  width: 100%;
`;


export const CloseContainer = styled.div`
position: fixed;
width: 4rem;
height: 4rem;
right: 5.2%;
top: -10%;
background-color: #020528;
border-radius: 50%;
`;

export const CloseModel = styled.h4`
color: #FF8A00;
font-size: 1rem;
cursor: pointer;
padding-top: 30% ;
text-align: center;
`;

export const AccountCheckBox = styled.div`
width: 100%;
height: 7rem;
margin-top: 1rem;
padding: 0.55rem 5rem;
`;