import React, { useState, useCallback, inputRef, useRef, useEffect } from 'react'
import { AuthContainer, AuthForm, LoginContainer, LogInHeader, LoginButton, LogInSpiner, AlertContainer, AlertMessage} from '../styles/Authstyles'
import Button from '../components/Button';
import Input from '../components/Input';
import FooterLayout from '../Layout/Footer/FooterLayout'
import Navigation from '../Layout/Header/Navigation';
import { useNavigate, Link } from 'react-router-dom';
import LogoImage from '../assets/pcz_bg.png';
import { Logos, Navbar } from '../styles/NavBarStyles'
//MUI
import Email from '@mui/icons-material/Email';
import Password from '@mui/icons-material/Password';

// Redux
import { useDispatch } from 'react-redux'
//import { setCredentials } from '../Redux/Auth/authSlice'
import { useLoginMutation } from '../Redux/Auth/authApiSlice'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRef = useRef()
  const errRef = useRef()
  const [errMsg, setErrMsg] = useState('')
  const [state, setState] = useState({
    email: "",
    password: "",
    errors: false,
  });

  const { email, errors, password, confirmPassword } = state;
 const [login, { isLoading }] = useLoginMutation()
//   console.log(errMsg)

 useEffect(() => {
 //userRef.current.focus()
}, [])

// useEffect(() => {
//   setErrMsg('');
// }, [email, password])

const handleChange = (e) => {
const {name, value} = e.target
setState({...state, [name]: value})
}

let data = [{
  "API_KEY": "pcZ$882193635247$63bd0f65a0d011.78149156",
    "RID": 1,
    "email": email,
    "pass": password
}]

//Set response data to state
  const handleSubmitAuth = async(e) => {
    e.preventDefault();
    try {
        const results = await login(data).unwrap()
       if (results.response_code === 1){ //response_code
         //  dispatch(setCredentials(results))
          // dispatch(setCredentialsEmailPassWord({email, password}))
           setState({})
           navigate('/home')
        } else if (results.response_code === 0){
          setErrMsg('Incorrect Email or Password');
        }
      } catch (err) {
        if (err) {
          console.log(err)
            setErrMsg('No Server Response');
        } else if (err.status === 400) {
            setErrMsg('Missing email or Password');
        } else if (err.status === 401) {
            setErrMsg('Unauthorized');
        } else {
            setErrMsg(err.data?.message);
        }
      //  errRef.current.focus();
    }
    setState((prevState) => ({
      ...prevState,
      errors
    }));
 
  }

  return (
    <>
    <Navigation />
    <AuthContainer>
      <LoginContainer>
        <LogInHeader>
        <Logos src={LogoImage} alt='Premier Credit'/>
        </LogInHeader>
        <AuthForm onSubmit={handleSubmitAuth}>
          {
            <>
              <Input
                style="default"
                icon={<Email />}
                type="email"
                name="email"
                value={state.email}
                placeholder="Enter your Email"
                onChange={handleChange}
              />
              <Input
                style="default"
                icon={<Password />}
                type="password"
                name="password"
                placeholder="Enter your password..."
                password
                value={state.password}
                onChange={handleChange}
              />
            </>
          }
        <LoginButton>
         <Button type="submit" margin="0 0 20px 0">
            {isLoading ? <LogInSpiner  size={20}   sx={{color:'#FF0000', p:0, m:0 }}/> : "Log In"}
          </Button>
        </LoginButton>
         
        </AuthForm>
        {/* <PasswordLinkButton type="text">
          Request password reset
        </PasswordLinkButton> */}
      </LoginContainer>
      <FooterLayout />
    </AuthContainer>
    </>
  )
}

export default Login
