import { apiSlice } from "../../app/api/apiSlice"
import { logOut } from "./authSlice"

// Generate winner
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: credentials => ({
                url: '/insureapi/request_handler.php',
                method: 'POST',
                body: {...credentials}
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                   // const id = getState().auth.user.id
                    const { data } = await queryFulfilled
                    console.log(data)
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
    })
})

export const {
    useLoginMutation,
} = authApiSlice 


// // Login query
// export const authApiSlices = apiSlices.injectEndpoints({
//     endpoints: builder => ({
//         login: builder.mutation({
//             query: credentials => ({
//                 url: '/insureapi/request_handler.php',
//                 method: 'POST',
//                 body: {...credentials}
//             }),
//             async onQueryStarted(arg, { dispatch, queryFulfilled }) {
//                 try {
//                    // const id = getState().auth.user.id
//                     const { data } = await queryFulfilled
//                    // console.log(data)
//                    // dispatch(logOut())
//                     setTimeout(() => {
//                         dispatch(apiSlice.util.resetApiState())
//                     }, 1000)
//                 } catch (err) {
//                     console.log(err)
//                 }
//             }
//         }),
//     })
// })

// export const {
//     useLoginMutation,
// } = authApiSlices 

