import React from 'react';
import { Footer, SmallFooterText } from '../../styles/FooterStyles';

const FooterLayout = () => {
    return (
        <Footer type="text" className='transparent mx-auto'>
            Copyright &copy; {new Date().getFullYear()} PremierCredit Zambia. All Rights Reserved.
            <SmallFooterText type="text">v0.0.3</SmallFooterText>
        </Footer>
    )
}

export default FooterLayout