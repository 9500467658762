import React from 'react'
import { Center, ModelContainer, Models, WinnerName, ModelHeading, CloseModel, WinnerNumber,  
         CloseContainer, } from '../styles/ModelStyles'

const Model = ({setOpenModel, WinnerData}) => {
   
  return (
    <ModelContainer>
      <Center>
        <Models>
                <ModelHeading>
                    CONGRATULATIONS !!!
                </ModelHeading>
                <CloseContainer onClick={()=>setOpenModel(false)}>
                <CloseModel>OK</CloseModel>
                </CloseContainer>
                <WinnerName>{`${WinnerData?.Firstname} ${WinnerData?.Lastname}`}</WinnerName>
                <WinnerNumber>{WinnerData?.Phone}</WinnerNumber>
        </Models>
      </Center>
    </ModelContainer>
  )
}

export default Model
