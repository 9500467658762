import React from 'react'
import { Logo, Navbar } from '../../styles/NavBarStyles'
import LogoImage from '../../assets/logo-black.png';

const Navigation = () => {
 
  return (
      <Navbar>
       {/* // <Logo src={LogoImage} alt='Premier Credit'/> */}
      </Navbar>
  )
}

export default Navigation